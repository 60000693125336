/**
*This mixin is for mobile view
*/
@mixin mobile {
    @media only screen and (min-width: 375px) {
        @content;
    }
}
@mixin tablet {
    @media only screen and (min-width: 768px) {
        @content;
    }
}
@mixin desktop {
    @media only screen and (min-width: 1280px) {
        @content;
    }
}
.button-base {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    
}

@mixin primery-btn {
   @extend .button-base;
    background: transparent;
    border-color: $primery-color;
    color: $app-black;
    font-family: $app-font-family;
    &:hover {
        background: $primery-color;
        color: $app-background-color;
        border-color: $primery-color;
    }
    &:disabled {
        background: rgba($color: $primery-color, $alpha: 0.3);
        color: rgba($color: $app-black, $alpha: 0.5);
        border-color: rgba($color: $primery-color, $alpha: 0.5)
    }
}

@mixin secondry-btn($bc:'white') {
    @extend .button-base;
    $hover-bg-color: $app-background-color;
    $hover-color: $primery-color;
    $bg-color: $primery-color;
    $color: $app-background-color;
    $border-color: $app-background-color;
     background: $bg-color;
     border-color: $border-color;
     color: $color;
     font-family: $app-font-family;
     cursor: pointer;
     &:hover {
         background: $hover-bg-color;
         color: $hover-color;
         border-color: $primery-color;
     }
     &:disabled {
         background: rgba($color: $bg-color, $alpha: 0.5);
         color: rgba($color: $app-black, $alpha: 0.5);
         border-color: rgba($color: $border-color, $alpha: 0.5)
     }
 }

//Primery color list
$black: #121618;
$white: white;
$red: red;
$green: green;
$blue:blue;
$primery-color:#fac564;
// $primery-color:#cd7e1c;
$alert-bg-color: #cd7e1c;

//app color list
$app-black: $black;
$app-light-black: $black;
$app-border-color:  #dee2e6;
$app-footer-background-color: #f5f5f5;
$app-background-color: $white;
$app-alert-color: $red;
$app-success-color: $green;
$app-blue-color: $blue;

//timeline

$app-timeline-circle-color: #dee2e6;

//app Shadow
$app-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
$app-hotel-card-shadow: 0 2px 8px #00000029;

// Progress Bar
$app-progress-bar-background-color: #ededed;
$app-progress-bar-field-background-color:$green;

@import "./_base";

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

*,
body,
html {
    margin: 0;
    font-family: $app-font-family;
}

* {
    box-sizing: border-box
}

.floating-form {
    width: 320px;

    @include desktop {
        // width: 620px; 
        width: 420px;
    }
}

.floating-label {
    position: relative;
    margin-bottom: 50px;
    background: $app-background-color;
}

.floating-input,
.floating-select {
    font-size: 14px;
    padding: 4px 4px;
    display: block;
    width: 100%;
    height: 30px;
    background-color: transparent;
    border: none;
    // border-bottom: 1px solid rgba($color: $app-black, $alpha: 0.5);
    border-bottom: 1px solid $app-border-color;
}

.floating-input:focus,
.floating-select:focus {
    outline: none;
    border-bottom: 1px solid rgba($color: $app-black, $alpha: 0.5);
}

label {
    color: rgba($color: $app-black, $alpha: 0.5);
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 5px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.floating-input:focus~label,
.floating-input:not(:placeholder-shown)~label {
    top: -18px;
    font-size: 16px;
    color: $app-black;
}

.highlight {
    position: absolute;
    height: 50%;
    width: 100%;
    top: 15%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}

.error {
    color: $app-alert-color;
    font-size: 15px;
    margin-top: 5px;
}

.full-page__wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.otp-validation,
.change-password,
.forgot-password,
.registration,
.login {
    @extend .full-page__wrapper;

    &__wrapper {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    &__block {
        h2 {
            margin-bottom: 50px;
        }
    }
}

.modal-dialog {

    // max-width: 100%;
    @include desktop {
        max-width: 80% !important;
    }
}

ngb-datepicker {
    background: white;
    color: $app-black;
    font-family: $app-font-family ;
    box-shadow: $app-shadow;
    padding: 20px;
    border-radius: 5px !important;
}

.ngb-dp-month-name {
    font-size: 15px !important;
    font-weight: 600 !important;
}

.ngb-dp-weekdays,
.ngb-dp-month-name,
.ngb-dp-header {
    background-color: white !important;
}

.ngb-dp-weekdays {
    border-bottom: none !important;
}

.ngb-dp-weekday {
    font-family: $app-font-family !important;
    color: black !important;
    font-style: normal !important;
}

.custom-day {
    color: $app-black;
}

.ngb-dp-day.disabled {
    background-color: #fac56480 !important;
    color: rgba($color: #000000, $alpha: 0.5) !important;
    // opacity: 1 !important;
}

// .dropdown.open { position: static; }

ngb-alert {
    background-color: $alert-bg-color !important;
    color: $app-background-color !important;
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;

    button {


        padding: 0px !important;
        margin-right: 12px !important;
    }

    .btn-close {
        background: url('../../assets/images/cross.svg') !important;
        background-repeat: no-repeat !important;
        top: 28px !important;
    }

}
.btn-wrapper {
    display: flex;
    flex-direction: column;
    a {
        cursor: pointer;
        color: $primery-color !important;
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 600;
    }
  }

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
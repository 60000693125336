.app-header-style {
    font-size: 15px;
    font-weight: 600;
    color: $app-black;
    margin-bottom: 10px;
    font-family: $app-font-family;
}
.heading-bold {
    font-size: 20px;
    font-weight: 600;
    color: $app-black;
    font-family: $app-font-family;
}
.prag-normal {
    font-size: 15px;
    font-weight: 500;
    color: $app-black;
    font-family: $app-font-family;
}
.prag-normal-12 {
    font-size: 12px;
    font-weight: 500;
    // color: rgba($color: $app-black, $alpha: 0.5);
    color: $app-black;
    font-family: $app-font-family;
}
.prag-bold-12 {
    font-size: 12px;
    font-weight: 600;
    // color: rgba($color: $app-black, $alpha: 0.5);
    color: $app-light-black;
    font-family: $app-font-family;
}
.prag-bold {
    font-size: 15px;
    font-weight: 600;
    color: $app-black;
    font-family: $app-font-family;
}
.mt-10 {
    margin-top: 10px;
}
.m-10 {
    margin: 10px;
}
.mb-10 {
    margin-bottom: 10px;
}
.app-secondry-btn {
    @include secondry-btn();
}
.app-primery-btn {
    @include primery-btn();
}
.app-row {
    display: flex;
    flex-direction: row;
}
.app-column {
    display: flex;
    flex-direction: column;
}
.app-row-spacebetween {
    @extend .app-row;
    justify-content: space-between;
}
.app-row-all-center {
    @extend .app-row;
    justify-content: space-between;
    align-items: center;
}